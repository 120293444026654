import { atom } from "recoil";

export const themeConfig = atom({
  key: "themeConyfig",
  default: {
    yosemitewestgate: {
      url: "https://www.yosemitewestgate.com",
      // url: "https://alpha.yosemitewestgate.com",
      theme: "yosemitewestgate",
      propertyId: "265",
      noCaptchaSiteKey: "6LdX5ggTAAAAAMEZInpJvEYoocLlK61Fg5qcdPZs",
      noCaptchaSecretKey: "6LdX5ggTAAAAACoiIaacDaFj-q1xZAKtafkycAAy",
      invisiblenoCaptchaSiteKey: "6LdtqnsUAAAAAEpaIIGPe80iNI3Xt12-CqeIIidV",
      invisiblenoCaptchaSecretKey: "6LdtqnsUAAAAAGiAFZ-LMeewztxkUY6Aw2EFZrkE",
      map_key:
        "ABQIAAAAqCDNgkIOC5oYXLAJXwMixRT2yXp_ZAY8_ufC3CFXhHIE1NvwkxSDmlo6UM7cTjQpcTbBmVNgINHNZA",
      destination_id: "1",
      cdn_active: "No",
      bin: "1",
      is_marketing: "0",
    },

    innatsaratoga: {
      url: "https://www.innatsaratoga.com",
      // url: "https://beta.innatsaratoga.com",
      theme: "innatsaratoga",
      propertyId: "761723",
      noCaptchaSiteKey: "6LfkWwQmAAAAAK7xy5bP2YS4esYy6NRoq7BZTF2u",
      noCaptchaSecretKey: "6LfkWwQmAAAAAOFSCb-cPQrt6OAYcY51QZU6bkIn",
      invisiblenoCaptchaSiteKey: "6Le2ahQiAAAAAGjVuvVO8qX-OtbzBpsFKH6N4Td1",
      invisiblenoCaptchaSecretKey: "6Le2ahQiAAAAADxTKMO2R_iR7xmb6uk7hHCP1rDV",
      map_key:
        "ABQIAAAAqCDNgkIOC5oYXLAJXwMixRT2yXp_ZAY8_ufC3CFXhHIE1NvwkxSDmlo6UM7cTjQpcTbBmVNgINHNZA",
      destination_id: "1",
      cdn_active: "No",
      bin: "1",
      is_marketing: "1",
    },

    hotellela: {
      url: "https://www.hotellela.com",
      // url: "https://beta.hotellela.com",
      theme: "hotellela",
      propertyId: "761729",
      noCaptchaSiteKey: "6LfkWwQmAAAAAK7xy5bP2YS4esYy6NRoq7BZTF2u",
      noCaptchaSecretKey: "6LfkWwQmAAAAAOFSCb-cPQrt6OAYcY51QZU6bkIn",
      invisiblenoCaptchaSiteKey: "6LfDtLApAAAAAG1Hhgl1DNX_Y19nGj0iF7Cp2I1O",
      invisiblenoCaptchaSecretKey: "6Le_ViAaAAAAAAcEXOzMWRfiSiWsMeTGSY4oZE_Q",
      map_key:
        "ABQIAAAAqCDNgkIOC5oYXLAJXwMixRT2yXp_ZAY8_ufC3CFXhHIE1NvwkxSDmlo6UM7cTjQpcTbBmVNgINHNZA",
      destination_id: "1328",
      cdn_active: "No",
      bin: "1",
      is_marketing: "1",
    },
    hotelcurrent: {
      url: "https://www.hotelcurrent.com",
      // url: "https://beta.hotelcurrent.com",
      theme: "hotelcurrent",
      propertyId: "726622",
      noCaptchaSiteKey: "6LdNvdkZAAAAAKqmFYQ_Xm5sEfHrM08CKg-kbGg0",
      noCaptchaSecretKey: "6LdNvdkZAAAAAFwFVvtEeipndZGRPEBEweOioJVh",
      invisiblenoCaptchaSiteKey: "6Le2ahQiAAAAAGjVuvVO8qX-OtbzBpsFKH6N4Td1",
      invisiblenoCaptchaSecretKey: "6Le2ahQiAAAAADxTKMO2R_iR7xmb6uk7hHCP1rDV",
      map_key:
        "ABQIAAAAqCDNgkIOC5oYXLAJXwMixRT2yXp_ZAY8_ufC3CFXhHIE1NvwkxSDmlo6UM7cTjQpcTbBmVNgINHNZA",
      destination_id: "1972",
      cdn_active: "No",
      bin: "1",
      is_marketing: "1",
    },

    santacruzcomfortinn: {
      url: "https://www.santacruzcomfortinn.com",
      // url: "https://beta.santacruzcomfortinn.com",
      theme: "santacruzcomfortinn",
      propertyId: "5002",
      noCaptchaSiteKey: "6LdlckwpAAAAAAeoKjyfhSG5H6eiTFadWVaa2F_p",
      noCaptchaSecretKey: "6LdlckwpAAAAAM3CsUKpb-CtMWiGxqJ6Urxic33f",
      invisiblenoCaptchaSiteKey: "6LdPMn4pAAAAAHXbFI52lPoyLfUDfVZNNhOM6tdl",
      invisiblenoCaptchaSecretKey: "6LdPMn4pAAAAAO6kadSHy3ZRlJ19wgcnYBJk8Lx5",
      map_key:
        "ABQIAAAAqCDNgkIOC5oYXLAJXwMixRT2yXp_ZAY8_ufC3CFXhHIE1NvwkxSDmlo6UM7cTjQpcTbBmVNgINHNZA",
      destination_id: "12",
      cdn_active: "No",
      bin: "1",
      is_marketing: "1",
    },
  },
});

export const getThemeName = atom({
  key: "getThemeName",
  default: sessionStorage.getItem("themeName"),
});

export const propertyData = atom({
  key: "propertyData",
  default: {},
});

export const imageLoader = atom({
  key: "imageLoader",
  default: false,
});

export const getSeoId = atom({
  key: "getSeoId",
  default: "",
});

export const getSeoData = atom({
  key: "getSeoData",
  default: [],
});

export const seoThemeDetails = atom({
  key: "seoThemeDetails",
  default: {
    home: "1",
    overview: "2",
    guestrooms: "3",
    reservations: "4",
    innsights: "5",
    specialspackages: "6",
    thingstodo: "7",
    attractions: "8",
    events: "9",
    directions: "10",
    gallery: "11",
    termsconditions: "12",
    privacypolicy: "13",
    sitemap: "15",
    signin: "16",
    register: "17",
    contactus: "18",
    weather: "20",
    video: "21",
    friendspage: "22",
    jobsection: "23",
    deals: "24",
    groupondeals: "25",
    dvirtualtours: "26",
    review: "27",
    sixplus: "28",
    yourguide: "29",
    faq: "30",
    news: "31",
    whatsnearby: "32",
    lostandfound: "33",
    menu: "34",
    nearbyhotels: "35",
    blog: "36",
    categorylist: "38",
    archivelist: "39",
    cookies: "40",
    personalinforequestform: "41",
    ada: "42",
    restaurantsmenu: "43",
    hotelhousekeeping: "44",
    cleaningprotocols: "45",
    pool: "46",
    parking: "47",
    creditcard: "48",
    fitnessroom: "49",
    localservices: "50",
    entertainment: "51",
    transportcommunte: "52",
    shoppingguide: "53",
    locationguide: "54",
    guestroomdetails: "55",
    TopStay: "56",
    pets: "57",
    collegeuniversity: "58",
    courtGovernment: "59",
  },
});

export const getSideNav = atom({ key: "getSideNav", default: false });

export const sideMenu = atom({ key: "sideMenu", default: false });
